var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-skeleton-wrapper",
    {
      attrs: { loading: _vm.$store.state.formLoading.isLoading },
      scopedSlots: _vm._u([
        {
          key: "loading",
          fn: function () {
            return [
              _c(
                "b-card",
                [
                  _c("b-skeleton", { attrs: { width: "85%" } }),
                  _c("b-skeleton", { attrs: { width: "55%" } }),
                  _c("b-skeleton", { attrs: { width: "70%" } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "b-form",
        [
          _c(
            "b-sidebar",
            _vm._b(
              {
                directives: [
                  {
                    name: "disable-all",
                    rawName: "v-disable-all",
                    value: !_vm.$can(
                      _vm.formDataCopy.id ? "update" : "create",
                      _vm.$route.meta.resource
                    ),
                    expression:
                      "!$can(formDataCopy.id ? 'update' : 'create',$route.meta.resource)",
                  },
                ],
                attrs: {
                  id: "sidebar-right",
                  "bg-variant": "white",
                  "no-header": "",
                  right: "",
                  backdrop: "",
                  shadow: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "footer",
                    fn: function () {
                      return [
                        _c(
                          "b-container",
                          [
                            _c(
                              "can",
                              {
                                attrs: {
                                  a: _vm.$route.meta.resource,
                                  I: _vm.formDataCopy.id ? "update" : "create",
                                },
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "w-100 text-right pb-2",
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { 400: true },
                                                },
                                              ],
                                              staticClass: "mr-1",
                                              attrs: {
                                                variant: "outline-primary",
                                                size: "sm",
                                              },
                                              on: { click: _vm.cancel },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    Cancel\n                  "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { 400: true },
                                                },
                                              ],
                                              attrs: {
                                                variant: "primary",
                                                size: "sm",
                                                type: "submit",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.validationForm.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "mr-50",
                                                attrs: { icon: "CheckIcon" },
                                              }),
                                              _c(
                                                "span",
                                                { staticClass: "align-middle" },
                                                [_vm._v("Done")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.sidebar,
                  callback: function ($$v) {
                    _vm.sidebar = $$v
                  },
                  expression: "sidebar",
                },
              },
              "b-sidebar",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "validation-observer",
                {
                  ref: "rules",
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.$scopedSlots, function (_, slot) {
                        return {
                          key: slot,
                          fn: function (props) {
                            return [_vm._t(slot, null, null, props)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                },
                [_vm._t("body")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }